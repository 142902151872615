<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 v-if="!this.form.id" class="card-title">{{ $t('App Project (Survey)') }}</h4>
                                    <h4 v-if="this.form.id" class="card-title">{{ $t('edit-project') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('Project Name (Survey Name)*')" label-for="project_name">
                                                <b-form-input
                                                    type="text"
                                                    id="project_name"
                                                    name="project_name"
                                                    autocomplete="new-password"
                                                    :placeholder="$t('project-name*')"
                                                    v-model="$v.form.project_name.$model" :state="$v.form.project_name.$dirty ? !$v.form.project_name.$error : null"
                                                    aria-describedby="input-1-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('survey-style*')" label-for="name">
                                                <b-form-select  v-model="$v.form.survey_style_id.$model" :state="$v.form.survey_style_id.$dirty ? !$v.form.survey_style_id.$error : null" aria-describedby="input-1-live-feedback" :options="surveyStylesOptions"  value-field="id" text-field="name">
                                                    <b-form-select-option v-if="surveyStylesOptions.length === 0" >{{ $t('No Style Found') }}</b-form-select-option>
                                                </b-form-select>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                      <!--  <div class="col-md-6">
                                            <b-form-group :label="$t('project-id*')" label-for="project_id">
                                                <b-form-input
                                                    type="text"
                                                    id="project_id"
                                                    name="project_id"
                                                    readonly
                                                    :placeholder="$t('project-id*')"
                                                    v-model="$v.form.project_id.$model" :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div> -->
                                    </div>

                                    <div class="row">
                                       
                                       <!-- <div class="col-md-6">
                                            <b-form-group :label="$t('Survey Name')" label-for="survey_name">
                                                <b-form-input
                                                    type="text"
                                                    id="survey_name"
                                                    name="survey_name"
                                                    autocomplete="new-password"
                                                    :placeholder="$t('Survey Name')"
                                                    v-model="$v.form.survey_name.$model" :state="$v.form.survey_name.$dirty ? !$v.form.survey_name.$error : null"
                                                    aria-describedby="input-1-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>-->
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('Source (Client)')" label-for="source">
                                                <b-form-input
                                                    type="text"
                                                    id="source"
                                                    name="source"
                                                    autocomplete="new-password"
                                                    :placeholder="$t('Source')"
                                                    v-model="form.source"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('estimated-start-date')" label-for="estimated_start_date">
                                                <b-form-input
                                                    type="date"
                                                    id="estimated_start_date"
                                                    name="estimated_start_date"
                                                    autocomplete="new-password"
                                                    :placeholder="$t('estimated-start-date')"
                                                    v-model="form.estimated_start_date"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('Platform')" label-for="platform">
                                                <b-form-input
                                                    type="text"
                                                    id="platform"
                                                    name="platform"
                                                    autocomplete="new-password"
                                                    :placeholder="$t('Platform')"
                                                    v-model="form.platform"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button type="submit" variant="success" :disabled="isDisabled" class="btn float-right btn-success">
                                                <span v-if="!this.form.id">{{ $t('submit-btn') }}</span>
                                                <span v-if="this.form.id">{{ $t('update-btn') }}</span>
                                            </b-button>
                                        </div>
                                    </div>
                                    <!--                                </form>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('Survey History') }}</h4>
                            <div class="header-buttons">
                            </div>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                :line-numbers="true"
                                @on-search="searchFn"
                                :search-options="{enabled: true,searchFn: searchFn}"
                                :totalRows="totalRecords"
                                :isLoading.sync="isLoading"
                                :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }"
                                :rows="rows"
                                :columns="columns"
                                class="pd-table">
                            <template slot="table-row" slot-scope="props">
                                <span
                                        v-if="props.column.field === 'action'"
                                        class="text-nowrap"
                                >
                                    <b-button size="sm" @click="editProject(props.row.id)"
                                            class="mr-1 btn-info text-white">
                                {{ $t('edit-btn') }}
                            </b-button>
                            <b-button size="sm" @click="deleteProject(props.row.id)"
                                        class="mr-1 btn-danger text-white">
                                {{ $t('delete-btn') }}
                            </b-button>
                            <b-button size="sm" @click="duplicateProject(props.row.id)"
                                        class="btn-duplicate btn-secondary text-white">
                                {{ $t('Duplicate') }}
                            </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    id: '',
                    project_name: '',
                    project_id: '',
                    survey_style_id: '',
                    survey_name: '',
                    source: '',
                    estimated_start_date: '',
                    platform: '',
                    description: '',
                    status: true,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                isDisabled: false,
                showPassword: true,
                showPassword1: true,
                inputType: 'password',
                inputType1: 'password',
                checkedPassword: false,
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                options: {
                    'yes': 'Yes',
                    'no': 'No',
                    'CBD': 'CBD'
                },
                columns: [
                    {
                        label: 'Survey Name',
                        field: 'project_name',
                        filterable: true,
                    },
                    {
                        label: 'Survey Template',
                        field: 'survey_style_name',
                        filterable: true,
                        sortable: true,
                    },
                    {
                    label: "Created At",
                        field: "created_at",
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                // category_id: '',
                categories: [],
                orders: [],
                surveyStylesOptions: [],
            };
        },

        validations: {
            form: {
                id: {},
                project_name: {
                    required,
                }, 
                project_id: {
                    required,
                },
                survey_style_id: {
                    required
                },
                // survey_name: {
                //     required,
                // },
            },
        },
        mounted() {
            // this.serverParams.user_id = this.$route.params.id;
            this.form.id = this.$route.params.id;
            if (this.form.id && this.form.id !== 'undefined') {
                this.readonly = true;
                this.getSpotter();
            } else {
                this.readonly = false;
            }
            this.isLoading = true;
            this.loadItems();
            this.getSurveyStyle();
        },
        methods: {
         onSubmit() {
                this.$v.form.$touch();

                // Check for any validation errors
                if (this.$v.form.$anyError) {
                    return;
                }

                // Check if the project name is empty
                if (!this.form.project_name) {
                    this.isDisabled = true;
                    this.$snotify.warning('Please fill in the project name.', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }

                // Disable the submit button to prevent multiple submissions
                
                // Call the API endpoint to add or update the project
                
                API.addEditProject(
                    this.form,
                    (data) => {
                        // Reset form fields and show success message upon successful submission
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.resetForm();
                        this.loadItems();
                    },
                    (err) => {
                        // Enable the submit button and show error message in case of API error
                        this.isDisabled = false;
                        this.$snotify.error(err, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                );
            },
            resetForm() {
                this.form = {
                    project_name: '',
                    survey_style_id: '',
                    survey_name: '',
                    source: '',
                    estimated_start_date: '',
                    platform: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
            if (status === 1) {
                return true;
            } else {
                return false;
            }
        },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            loadItems() {
                this.isLoading = true;
                API.getAllProjects(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = [...data.data.data];
                        this.form.project_id = data.project_id;
                    },
                    err => {
                    }
                )
            },
            editProject(e) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                this.$v.form.$reset();
                API.editProject(
                    e, data => {
                        this.form.id = data.data.id;
                        this.form.project_name = data.data.project_name;
                        this.form.project_id = data.data.project_id;
                        this.form.survey_style_id = data.data.survey_style_id;
                        this.form.survey_name = data.data.survey_name;
                        this.form.source = data.data.source;
                        this.form.platform = data.data.platform;
                        this.form.estimated_start_date = data.data.estimated_start_date;
                    },
                    err => {
                    }
                )
            },
            deleteProject(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        API.deleteProject(
                            e,
                            data => {
                                this.loadItems();
                                this.resetForm();
                                this.$snotify.success('Record has been deleted!');
                                this.form.id = '';
                                this.form.logic = false;
                                this.form.question = '';
                                this.form.response = '';
                                this.$v.form.$reset();
                                this.showSubQuestions = false;
                            },
                            err => {
                            })
                    }
                })
            },
            duplicateProject(id) {
                // Call the API function to duplicate the project
                API.duplicateProject(id,
                    (data) => {
                        // Handle success, e.g., show a success message
                        this.$snotify.success('Project duplicated successfully');
                        // Reload the projects or update the list to reflect the changes
                        this.loadItems();
                    },
                    (error) => {
                        // Handle error, e.g., show an error message
                        this.$snotify.error('Failed to duplicate project');
                    }
                );
            },
            getSurveyStyle() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                }
                API.getSurveyStyle(
                    data,
                    data => {
                        this.surveyStylesOptions = data.data;
                    },
                    err => {
                    }
                )
            },
        }, computed: {},

    }
</script>

<style>
    .btn-duplicate {
        width: 80px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
